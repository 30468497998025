import React, {Component} from 'react'
import {graphql, Link, navigate} from 'gatsby'
import styled from "styled-components"
import {media} from "utils/Media"
import Layout from 'components/Layout/Layout'
import {Container} from "reactstrap";
import CleanHeader from "../components/Header/CleanHeader";
import CleanFooter from "../components/Footer/CleanFooter";
import {StaticImage} from "gatsby-plugin-image";
import ChristmasTourHomeTemplate from "templates/ChristmasTourHomeTemplate"

const Content = styled.div`
  padding: 1rem 0;
    
    @media ${media.sm} {
        padding: 3rem 0;
    }

  .list {

    .listRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.5rem auto;
      padding-bottom: 1rem;
      border-bottom: 1px dotted black;
    }

    .location {
      width: 280px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        display: inline-block;
        width: 35px;
        margin-right: 5px;
      }

      h3 {
        text-transform: none !important;
        text-decoration-color: #DE691B;
        text-decoration-thickness: auto;
        text-decoration-line: underline;
        display: inline-block;
        margin-bottom: 0;
      }
    }

    .when {
      display: none;
      @media ${media.lg} {
        display: block;
        width: 170px;
      }
    }

    .where {
      display: none;
      @media ${media.lg} {
        display: block;
        flex-grow: 1;
      }
    }

    .button {
      width: 100px;
      @media ${media.sm} {
        width: 140px;
      }
      @media ${media.lg} {
        width: 233px;
      }

      .btn {
        width: 100px;
        height: 40px;
        padding: 11px 0px;
        font-size: 1.125rem;
        @media ${media.sm} {
          width: 140px;
          padding: 9px 10px;
          font-size: 1.5rem;
        }
        @media ${media.lg} {
          width: 233px;
          height: 50px;
          padding: 13px 10px;
        }
      }
    }
  }
`

class WelcomePage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            locations: [
                {
                    flag: "images/flags/uk.png",
                    place: "London",
                    when: "Now Playing",
                    where: "The Prince of Wales Theatre",
                    slug: "/london/",
                    buttonCopy: "VISIT SITE"
                },
                {
                  flag: "images/flags/uk.png",
                  place: "Manchester",
                  when: "Coming Soon",
                  where: "On Sale from 10 November",
                  slug: "/uk-eu/signup/?city=manchester",
                  buttonCopy: "FIND OUT MORE"
                },
                {
                  flag: "images/flags/uk.png",
                  place: "Edinburgh",
                  when: "Coming Soon",
                  where: "On Sale from 10 November",
                  slug: "/uk-eu/signup/?city=edinburgh",
                  buttonCopy: "FIND OUT MORE"
                },
                {
                  flag: "images/flags/uk.png",
                  place: "Birmingham",
                  when: "Coming Soon",
                  where: "On Sale from 10 November",
                  slug: "/uk-eu/signup/?city=birmingham",
                  buttonCopy: "FIND OUT MORE"
                },
                {
                    flag: "images/flags/usa.png",
                    place: "New York",
                    when: "Now Playing",
                    where: "Eugene O'Neill Theatre",
                    slug: "https://bookofmormonbroadway.com/",
                    buttonCopy: "VISIT SITE",
                    external: true
                },
                {
                    flag: "images/flags/usa.png",
                    place: "US Tour",
                    when: "Now Playing",
                    where: "Find out more",
                    slug: "https://thebookofmormontour.com/",
                    buttonCopy: "VISIT SITE",
                    external: true
                }


            ],
        }
    }

    render() {
      return <ChristmasTourHomeTemplate />;

        // return (
        //     <Layout>
        //         <CleanHeader
        //             hideSocials={true}
        //         />
        //         <Container fluid={true}>
        //             <Container>
        //                 <Content>
        //                     <h1 className="mb-3 mb-sm-5">Choose your location </h1>
        //                     <div className="list">
        //                         {
        //                             this.state.locations.map((location, id) => (
        //                                 <div className="listRow" key={id}>
        //                                     <div className="location">
        //                                         <img src={location.flag} alt=""/>
        //                                         <h3>{location.place}</h3>
        //                                     </div>
        //                                     <div className="when">{location.when}</div>
        //                                     <div className="where">{location.where}</div>
        //                                     <div className="button">
        //                                         {location.external ?
        //                                             <a href={location.slug} target="_blank" rel="noopener"
        //                                                className="btn btn-orange">
        //                                                 {location.buttonCopy}
        //                                             </a>
        //                                             :
        //                                             <Link to={location.slug}
        //                                                   className="btn btn-orange">{location.buttonCopy}</Link>
        //                                         }
        //                                     </div>
        //                                 </div>
        //                             ))
        //                         }
        //                     </div>
        //                 </Content>
        //             </Container>
        //         </Container>
        //         <CleanFooter data={data}/>
        //     </Layout>
        // )
    }
}

export default WelcomePage

//* setting page redirect in cloudflare to reidrect bradford pages


// {
//   "id": "bradfordHome",
//   "siteId": "bradford",
//   "page": true,
//   "language": "en",
//   "languageCode": "en-GB",
//   "phase": "open",
//   "template": "home-template",
//   "path": "/bradford/",
//   "metaTitle": "Tickets",
//   "metaDescription": "TICKETS NOW ON SALE for The Book of Mormon, Bradford - the hit musical by Matt Stone and Trey Parker, creators of South Park.",
//   "multiLang": false,
//   "region": "UK",
//   "displayHeader": true
// },
// {
//   "id": "bradfordThankYou",
//   "siteId": "bradford",
//   "page": true,
//   "language": "en",
//   "languageCode": "en-GB",
//   "phase": "open",
//   "template": "thank-you-template",
//   "path": "/bradford/thank-you/",
//   "metaTitle": "Thank you",
//   "metaDescription": "",
//   "multiLang": false,
//   "region": "UK",
//   "displayHeader": true
// },
// {
//   "id": "bradfordTickets",
//   "siteId": "bradford",
//   "page": true,
//   "language": "en",
//   "languageCode": "en-GB",
//   "phase": "open",
//   "template": "tickets-template",
//   "path": "/bradford/tickets/",
//   "metaTitle": "Tickets for bradford",
//   "metaDescription": "bradford Playhouse. The Book of Mormon, The hit musical by Matt Stone and Trey Parker, creators of South Park.",
//   "multiLang": false,
//   "region": "UK",
//   "displayHeader": true
// },
// {
//   "id": "bradfordShow",
//   "siteId": "bradford",
//   "page": true,
//   "language": "en",
//   "languageCode": "en-GB",
//   "phase": "open",
//   "template": "show-template",
//   "path": "/bradford/show/",
//   "metaTitle": "About",
//   "metaDescription": "Check out the full Cast & Creatives list for the Book Of Mormon London Musical.",
//   "multiLang": false,
//   "region": "UK",
//   "displayHeader": true
// },
// {
//   "id": "bradfordPress",
//   "siteId": "bradford",
//   "page": true,
//   "language": "en",
//   "languageCode": "en-GB",
//   "phase": "open",
//   "template": "press-template",
//   "path": "/bradford/press/",
//   "metaTitle": "Press",
//   "metaDescription": "",
//   "multiLang": false,
//   "region": "UK",
//   "displayHeader": true
// },
// {
//   "id": "bradfordGallery",
//   "siteId": "bradford",
//   "page": true,
//   "language": "en",
//   "languageCode": "en-GB",
//   "phase": "open",
//   "template": "gallery-template",
//   "path": "/bradford/gallery/",
//   "metaTitle": "Gallery",
//   "metaDescription": "Check out the on-stage photo shots of the Book Of Mormon Musical London Cast.",
//   "multiLang": false,
//   "region": "UK",
//   "displayHeader": true
// },
// {
//   "id": "bradfordPrivacy",
//   "siteId": "bradford",
//   "page": true,
//   "language": "en",
//   "languageCode": "en-GB",
//   "phase": "open",
//   "template": "privacy-template",
//   "path": "/bradford/privacy-policy/",
//   "metaTitle": "Privacy Policy",
//   "metaDescription": "View the privacy policy for The Book of Mormon, the hit musical by Matt Stone and Trey Parker, creators of South Park. Visiting Sunderland in 2022.",
//   "multiLang": false,
//   "region": "UK",
//   "displayHeader": true
// },
// {
//   "id": "bradfordCookie",
//   "siteId": "bradford",
//   "page": true,
//   "language": "en",
//   "languageCode": "en-GB",
//   "phase": "open",
//   "template": "cookie-template",
//   "path": "/bradford/cookie-privacy/",
//   "metaTitle": "Cookie Policy",
//   "metaDescription": "View the cookie policy for The Book of Mormon, the hit musical by Matt Stone and Trey Parker, creators of South Park. Visiting Sunderland in 2022!",
//   "multiLang": false,
//   "region": "UK",
//   "displayHeader": true
// },